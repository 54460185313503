//GLOBALS
.projectHeader {
  // font-family: 'Comfortaa', cursive !important;
  font-family: 'Raleway', cursive !important;
}

@mixin transparent-bg($r, $g, $b, $opacity) {
  background-color: rgba($r, $g, $b, $opacity) !important;
}

@mixin hor-ctr {
  text-align: center !important;
}

@mixin vertical-ctr {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin center-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin shallow-shadow {
  -webkit-box-shadow: 3px 4px 1px 0px rgba(0, 0, 0, 0.9);
  -moz-box-shadow: 3px 4px 1px 0px rgba(0, 0, 0, 0.9);
  box-shadow: 3px 4px 1px 0px rgba(0, 0, 0, 0.9);
}

@mixin deep-shadow {
  -webkit-box-shadow: 7px 9px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 9px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 9px 5px 0px rgba(0, 0, 0, 0.75);
}

@mixin no-shadow {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

$fx: all ease-in-out 1s;
$debug: false;

@mixin rounded-btn {
    width: 8rem;
    margin-bottom: 2%;
    background-color: $violet !important;
    border-color: $violet !important;
    border-radius: 20px !important;
    font-weight: bold;
    @include shallow-shadow;
    transition: $fx;
}

@mixin drama-btn {
    width: 10rem;
    height: 5rem;
    // margin-bottom: 2%;
    // bottom: 2rem;
    background-color: white !important;
    // border-color: $violet !important;
    // border-radius: 2px !important;
    font-weight: 400;
    font-size: 2em !important;
    // font-family: 'BriceBoldExpanded', sans-serif !important;
    font-family: 'Yeseva One', sans-serif !important;
    @include shallow-shadow;
    transition: $fx;
    z-index: 1;
    overflow: if($debug, unset, hidden);
    text-decoration: none;
    font-size: 2em;
    padding: 0.75em 1em;
    color: $grass;
    border: 0.15em solid $grass;
    border-radius: calc(0.75em + 0.5em + 0.15em);
    transition: 4s;
}

@mixin drama-btn-hover {
    // width: 11rem;
    // height: 6rem;
    // background-color: $deep-sea !important;
    // border-color: $deep-sea !important;
    // @include deep-shadow;
    // transition: $fx;
    color: white !important;
}

@mixin rounded-btn-hover {
    width: 10rem;
    background-color: $olive !important;
    border-color: $olive !important;
    @include deep-shadow;
    transition: $fx;
}

//color scheme - succulents
// $lavender: #b3acbc;
// $deep-purple: #74466a;
// $violet: #3b3348;
// $deep-sea: #5b7478;
// $grass: #98b8a3;
// $succulent: #d0dac1;

//new color scheme - terracota
$grass: #98b8a3;
$succulent: #d0dac1;
$olive: #5c6140;
$sandy: #ffd7a3;
$terracota: #ec906d;
$sunset: #ed7844;
$warmWhite: rgb(228, 220, 202);

.hidden-link {
  opacity: 0 !important;
}
//NAVIGATION
.dropdownMenuItem, .css-6hp17o-MuiList-root-MuiMenu-list{
  background-color: $sandy !important;

}

.dropdownLink{
  color: $olive !important;
  text-decoration: none;
}

.navbar {
  background-color: $olive !important;
  // position: sticky !important;
  position: fixed !important;
  top: 0;

  .nav {
    text-align: center;
  }

  .navlink {
    text-decoration: none;
    margin-left: 0.5em;
    margin-right: 0.5em;
    
    .navlinkBtn {
      color: $warmWhite !important;
      font-family: 'Raleway', sans-serif;
      width: 100%;
      margin: 0 !important;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .navlinkBtn:hover {
      color: $sandy !important;
      transition: all 0.5s cubic-bezier(1, 0, 0, 1);
    }
  }
  
  .navlink:hover {
    color: $sandy !important;
    background-color: $sunset !important;
    transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  }

}


//GLOBAL STYLES
.section-div {
  height: 100%;
}

.link {
  text-decoration: none;
  color: $terracota !important;
}

//POLICY PAGE
.title-container {
  position: relative;
  @include center-center();

  img {
    width: 100%;
    height: auto;
  }

  h1 {
    position: absolute;
    top: 10% !important;
    font-size: 4.5em;
  }
}
