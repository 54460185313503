//GLOBAL
@import "../../App.scss";

//HOME PAGE
.page-title {
    margin-top: 5em;
}

.pages {
    .title-link {
        text-decoration: none;
        color: black;
    }
}

.body-link {
    color: black !important;
    &:visited{
        color: black !important;
    }
}

//INTRO
.intro-section {
    background-color: $warmWhite;
    img {
        width: 100vw;
        height: 100vh;
    }

    @media only screen and (max-width: 800px) {
        img{
            height: auto !important;
            // width: 80vw !important;
        }
    }

    position: relative;
    
    h1 {
        font-size: calc(80vw / 20);
        line-height: 1em;
        color: #8E812A;
        text-shadow: 1px 1px 2px #000;
    }

    @media only screen and (max-width: 800px){
        h1 {
            color: #8E812A;
            text-shadow: 0.5px 0.5px 1px #000;
        }
    }
    
        #title-tag {
            position: absolute;
            text-align: left;
            top: 20%;
            left: 8%;
            // transform: translate(-10%, -10%);
            animation-delay: 2s;
            animation: slideFromLeftTitle 2s linear;
        }
        
        #header-name {
            position: absolute;
            text-align: right;
            // margin-right: 1em;
            top: 20%;
            right: 3%;
            // transform: translate(70%, 0%);
            animation-delay: 2s;
            animation: slideFromRightTitle 2s linear;
        }

        // .introBtn {
        //     position: absolute;
        //     left: 46%;
        //     color: $olive;
        //     font-weight: 700;
        //     top:30%;
        //     background-color: $succulent;
        //     text-decoration: none;
        //     padding: 0.5em 2em;
        //     border-radius: 3px;
        //     @include shallow-shadow;
        //     opacity: 0;
        //     animation-delay: 2000ms;
        //     animation-name: fadeInAnimation;
        //     animation-timing-function: ease;
        //     animation-duration: 2s;
        //     animation-iteration-count: 1;
        //     animation-fill-mode: forwards;
        //     -webkit-animation-delay: 2000ms;
        //     -webkit-animation-name: fadeInAnimation;
        //     -webkit-animation-timing-function: ease;
        //     -webkit-animation-duration: 3s;
        //     -webkit-animation-iteration-count: 1;
        //     -webkit-animation-fill-mode: forwards;
        // }

        // .introBtn:hover {
        //     @include no-shadow();
        //     background-color: $terracota !important;
        //     color: $olive;
        //     transition: all ease-in-out 0.5s;
        // }
}

//ABOUT SECTION
//mobile
@media only screen and (max-width: 800px) {
    // .section-one {
    //     margin-left: 0.5em;
    //     margin-right: 0.5em;
    // }
    .section-initial,
    .about-txt {
        p {
            margin: 1em !important;
            font-size: 1em !important;
        }
    }

    .company-title{
        font-size: 2.5em !important;
    }
}

.section-initial,
.about-txt {
    p {
        margin: 2em;
        font-size: 1.3em;
    }
}

.company-title{
    font-size: 3.2em;
}

.section-one {
    background-color: $sandy;
    img {
        width: 100%;
        height: auto;
        // border-radius: 50%;
    }

    .portrait,
    .personal-about,
    .about-txt {
        width: 100%;
        height: 100%;
        padding: 0 !important;
    }

    .personal-about {
        background-color: transparent;
    }

    .about-txt {
        @include center-center;
    }
}

.section-div {
    @include center-center;
}

//SKILLS SECTION
.grid-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px; /* Adjust spacing as needed */
  }
  
  .skills-list-item {
    display: flex;
    align-items: center;
  }
  
  .skills-list-item p {
    margin-left: 8px; /* Adjust spacing between the icon and text as needed */
  }

.skill-container {
    @include center-center;
}

.skill-page {
    width: 90%;
    // margin: auto;
}

.section-two {
    @include transparent-bg(92, 97, 64, 0.8);
    border: 3px solid $olive;
    color: $warmWhite;
    border-radius: 5px;
    height: 100%;
    @include center-center;

    .skill-container {
        padding: 2em 1em;
        text-align: left !important;
    }
}

//SERVICES SECTION
.service-page {
    ul {
        list-style: none;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;
        text-align: start;
    }

    .tagline {
        color: $olive;
    }

    .consult-container,
    .addon-container {
        position: relative;

        img {
            width: 100%;
            height: auto;
        }

        .consult {
            position: absolute;
            top: 10%;
            border-radius: 2px;
            // border: 1px solid $grass;
            margin: auto 10%;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        }

        .addon-txt {
            position: absolute;
            top: 20%;
            margin: auto 0;
            text-align: center;

            h2 {
                font-size: 2.2em;
                margin-bottom: 0;
            }
            p {
                font-size: 0.9em;
                color: $sunset !important;
                font-weight: 800;
            }
            ul {
                margin-top: 1em;
                li {
                    margin-bottom: 0.5em;
                    font-size: 1.2em;
                }
            }
        }
        
        @media screen and (max-width: 800px) {
            .consult{
                @include transparent-bg(236, 144, 109, 0.8);
                text-shadow: 2px 2px 2px rgba(0,0,0,0);
                position: relative;
                margin: auto;
                margin-top: 0.8em !important;
            }

            .improv-title,
            .pckg-title {
                margin-top: 2em !important;
            }

            .addon-txt {
                position: relative;
                h2 {
                    font-size: 1.5em;
                    margin-bottom: 0;
                }
                small {
                    font-size: 0.75em;
                    color: $sunset;
                }
                ul {
                    margin-top: 1em;
                    li {
                        margin-bottom: 0.5em;
                        font-size: 0.8em;
                    }
                }
            }
            
        }

    }

    .grid-txt {
        border-radius: 1px;
        padding: 0.5em;
        flex-grow: 1;
        @include shallow-shadow();

        ul {
            margin-left: 8em;
        }

        .desc {
            margin: auto 3em;
        }

        .automation-list {
            margin-left: 5em !important;
            margin-top: 1em;
        }
    }

    .grid-txt:hover {
        @include no-shadow();
        background-color: $terracota !important;
        color: white;
        transition: all ease-in-out 0.5s;
    }

    .srv-container {
        width: 100%;
        padding-left: 1em;
        padding-right: 1em;

        > p {
            color: $olive;
            font-weight: 500;
            text-decoration: underline;
        }

        a {
            text-decoration: none;
            color: black;
        }
    }
    
    #automation-serv {
        background-color: $warmWhite;
        padding-bottom: 3em;
    }
    
    .tech-improvements {
        padding-left: 1em;
        padding-right: 1em;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
    }

    .package, .improvement {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .grid-txt {
            // @include transparent-bg(116, 70, 106, 0.7);
            background-color: $sandy;
            border: 1px solid $olive;
            transition: all ease-in-out 0.5s;
        }
        height: 100%;
    }

    .service-packages {
        margin-bottom: 3em;
        padding-left: 0.5em;
        padding-right: 0.5em;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
    }

    .improv-title,
    .pckg-title {
        margin-top: 4em;
    }
    
    .tech-improvements {
        .grid-txt {
            // @include transparent-bg(152, 184, 163, 0.8);
            background-color: $succulent;
            border: 1px solid $olive;
            transition: all ease-in-out 0.5s;
            padding: 0.8em;
        }
    }
}

//BOOKING SECTION
@media screen and (max-width:800px) {
    .section-five {
        .booking-row {
            .booking-btn {
                width: 90% !important;
            }
            p {
                margin: 0.5em 1em !important;
            }
        }
    }
}
.booking-page {
    margin-top: 0;

    .section-five {
        .booking-row {
            .booking {
                width: 100%;
                height: 100%;
            }
            .booking-btn {
                width: 35%;
                height: 5em;
                border-radius: 5px;
                border: 1px solid $grass;
                background-color: $olive;
                color: $succulent;
                font-weight: bold;
                font-size: 1em;
                transition: all ease-in-out 1s;
            }
            .booking-btn:hover {
                width: 40%;
                color: $olive !important;
                background-color: $succulent !important;
                @include shallow-shadow;
                transition: all ease-in-out 1s;
                cursor: pointer;
            }
            .booking-img img {
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
        }
    }
}

// //PROJECT SECTION
// .section-three {
//     margin: 2em auto;
//     padding: 0 2em;

//     .cardmedia {
//         // width: 100%;
//         // max-height: 400px;
//     }

//     .cards {
//         @include transparent-bg(143, 114, 132, 1);
//         @include shallow-shadow();
//         color: white;

//         h3 {
//             padding: 0;
//         }
//     }

// }


//CONTACT US
@media screen and (max-width:800px) {
    .dash-form{
        margin: auto 1em;
    }
}

.form-grid {
    padding: 0.5em;
}

.form-btn {
    width: 35%;
    height: 4em;
    border-radius: 5px;
    border: 1px solid $terracota !important;
    background-color: $terracota !important;
    color: $olive !important;
    font-weight: 800;
    font-size: 1em;
    transition: all ease-in-out 1s;
}
.form-btn:hover {
    width: 40%;
    background-color: $sunset !important;
    @include shallow-shadow;
    transition: all ease-in-out 1s;
    cursor: pointer;
}

//POLICY PAGE
@media screen and (max-width:800px) {
    .section-six {
        margin: 1em !important;
    }
}
.section-six {
    text-align: left !important;
    align-items:flex-start !important;
    justify-content: flex-start !important;
    margin: 2em;

    li {
        margin: 0.8em auto;
    }
}

@keyframes slideFromLeftTitle {
    from {
      transform: translate(-100%, -10%); /* Move the element 100% to the left initially */
    }
    to {
      transform: translate(10, -10%); /* Move the element back to its original position horizontally */
    }
}

@keyframes slideFromRightTitle {
    from {
      transform: translate(100%, 10%); /* Move the element 110% to the right initially, including the margin */
    }
    to {
      transform: translate(-10, 10%); /* Move the element back to its original position, considering the margin */
    }
}

//   @keyframes fadeInAnimation {
//     0% {
//         opacity: 0;
//     }
//     100% {
//         opacity: 1;
//     }
// }