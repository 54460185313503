@import url("https://assets.calendly.com/assets/external/widget.css");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap');

// @font-face {
//   font-family: "BriceBoldExpanded";
//   src: local("BriceBoldExpanded"),
//     url("./fonts/Brice\ Bold\ Expanded.otf") format("opentype");
//   font-weight: normal;
// }

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


html {
  margin: 0;
  text-align: center;
  scroll-behavior: smooth;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #d0dac1;
  width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: oscine, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

//FONTS

h1 {
  font-family: "Yeseva One", serif;
  // font-family: "BriceBoldExpanded", serif;
}

h2,
h3,
h4,
h5 {
  font-family: 'Ubuntu Sans', sans-serif;
}

small,
p {
  font-family: "Raleway", sans-serif;
}


.spacer {
  height: 5em;
}

.pages {
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 2s;
}

/* section 1 */

.sectionone img {
  width: 100%;
  height: auto;
}

/* section 2 */

.title {
  margin-top: 10%;
}

.sectiontwo {
  background-color: #373543;
  color: white;
  padding-top: 2%;
  margin-top: 3%;
  border-radius: 5px;
  border: solid 1px #2199be;
}

.sectiontwo img:hover {
  -webkit-box-shadow: 7px 9px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 9px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 9px 5px 0px rgba(0, 0, 0, 0.75);
  transition: all ease-in-out 1s;
}

.rowtwo {
  margin-top: 5%;
}

/* projects */

.sectionthree img {
  width: 100%;
  height: auto;
  max-height: 100px;
  border-radius: 5px;
}

.post-card {
  background-color: rgba(0, 0, 0, 0.7) !important;
  color: white;
  margin: 1%;
  -webkit-box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

.post-card:hover {
  opacity: 0.9;
}

.post-card .card-img-block {
  width: 91%;
  margin: 0 auto;
  position: relative;
  top: -20px;
}

.post-card .card-img-block img {
  border-radius: 5px;
  -webkit-box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 5px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

.post-card h5 {
  font-weight: 600;
}

/* animations */

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
